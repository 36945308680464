import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/es"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import TextMosaic from "../components/ui-sections/text-mosaic"
import TextSection from "../components/ui-sections/text-section"
import ImageGallery from "../components/ui-sections/image-gallery"

const OrigensPage = (props) => {
	const heroText = "Orígenes";
	const sentenceText = "Todo empieza en junio de 1969";
	const titleAvi = "Todo empieza en junio de 1969";
	const titlePare = "El gran salto, manteniendo la esencia";
	const titlePere = "El sello gastronómico de Pere";
	const contentAvi = "En junio de 1969 el abuelo Pere y la iaia Carmeta inauguraban Cal Pere del Maset. El restaurante ya desde el primer día se hizo inolvidable para la familia Palau Sardà, donde la hija celebró su boda el día de la inauguración. Y aquel restaurante de carne a la brasa, tirado hacia adelante por los abuelos, y dos pequeños chiquillos como eran entonces nuestro padre y nuestro tío, fue aumentando la capacidad de los comedores, de la cocina, y en consecuencia la carta. Con un asador de leña se cocinaban paletillas, cabritos, conejos y también nacieron los guisos y horno que encontramos hoy en la carta. Los abuelos pusieron los cimientos de lo que es el restaurante hoy en día. Los fundamentos de tres generaciones diferentes hasta día de hoy.";
	const contentPare = "Nuestro padre tomó el relevo de nuestro abuelo, y él y nuestra madre han hecho del restaurante un referente del Alt Penedès, de la cocina del mejor producto. Entre los años 80 y los 2000, el restaurante creció mucho con la creación de los comedores privados, de la bodega, y de la renovación entera de la cocina y los comedores. Pero manteniendo el alma, la esencia del primer día. Cal Pere del Maset es un lugar donde se acomulan recuerdos de todo tipo, generaciones de familias que venían con los abuelos y ahora vienen con los hijos. De buena comida y de buen beber, pero también de nostalgia y de sentimientos. Y esta esencia es la que nuestro padre y nuestra madre le han dado siempre al restaurante con su personalidad. Siguiendo el legado de los abuelos, para que nuestros clientes, amigos, conocidos y desconocidos vengan a comer y se sientan como en casa."
	const contentPere = "Pere es la tercera generación, y quien hoy lidera, junto con nuestra madre, el día a día del restaurante. Crecido entre brasas y fogones, desde muy joven siempre tuvo claro que quería dedicarse a la cocina. Comenzó tostando el pan con sólo 9 años, y creció combinando los estudios con ayudar siempre a la cocina. Una vez terminados los estudios, se formó en restaurantes de prestigio del mundo de Estrella Michelin, como Les Crayères (Reims, Francia) o el Drolma del Chef Fermí Puig en Barcelona. Y hoy, desde hace ya más de una década, mantiene una oferta gastronómica de temporada, combinando la carta de toda la vida con menús degustación, platos de temporada y maridajes para los paladares más exigentes. Y como los abuelos, y nuestros padres, manteniendo el trato cercano y familiar.."
	const textSectionText1 = "\"Los orígenes son los abuelos. El estilo, la brasa, el fuego, los guisos, el sofrito, el pan tostado, el tacto, el trato el esfuerzo y el sacrificio, el carácter, el empuje, la lucha, la vida, parte de lo que somos y de lo que vendrá. Los abuelos. Nuestro querido abuelo Pere y nuestra querida abuela Carmeta.\""
	const textSectionText2 = "\"Pit i collons.\" De nuestro abuelo a nuestro padre, y de nuestro padre a Pere y a mí."


	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Orígenes"
	    	lang="es"
	    	description={textSectionText1}
	    />
		  <HeroImage 
		  	titleText={heroText} 
		  	sentenceText={sentenceText}
		  	image="casa_antiga" 
		  />
		  <TextSection textSectionText={textSectionText1} />
	    <StaticQuery
	      query={graphql`
	        # Replace this comment with the GraphQL query above
	        {
	          allOrigensImagesEsYaml {
	            edges {
	              node {
	                title
	                image {
	                	id
	                  childImageSharp {
	                    fluid {
	                      aspectRatio
	                      originalImg
	                      # Use gatsby-image later for better user experience
	                      ...GatsbyImageSharpFluid
	        }}}}}}}
	      `}
	      render={data => (
	        <ImageGallery
	          itemsPerRow={[2, 3]} // This will be changed to `[2, 3]` later
	          images={data.allOrigensImagesEsYaml.edges.map(({ node }) => ({
	          	id: node.image.id,
	            ...node.image.childImageSharp.fluid,
	            caption: `${node.title}`,
	          }))}
	        />
	      )}
	    />
	    <TextMosaic 
	    	titleAvi={titleAvi}
	    	titlePare={titlePare}
	    	titlePere={titlePere}
	    	contentAvi={contentAvi}
	    	contentPare={contentPare}
	    	contentPere={contentPere}
	    />
	    <TextSection textSectionText={textSectionText2} />
	    <HeroImage  image="paisatge" />
	  </Layout>
	)
}

export default OrigensPage
